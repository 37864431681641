import { Button, Column, Form, TextInput } from 'carbon-components-react'
import { Link, useNavigate } from 'react-router-dom'
import { useCallback, useState } from 'react'

import { InlineBanner } from 'components/inline-banner/InlineBanner'
import { LoadingButton } from 'components/loading-button/LoadingButton'
import { LoginLayout } from 'layouts/login-layout/LoginLayout'
import { Pages } from 'constants/pages'
import { Params } from 'constants/params'
import { Stack } from '@carbon/react'
import { signUp } from 'aws-amplify/auth'
import { useSessionStorage } from 'hooks/useSessionStorage'

export type UserRegistrationData = {
  username: string
  password: string
  email: string
}

export function Register() {
  const [hasError, setHasError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  const [, setEmailToConfirm] = useSessionStorage(Params.EMAIL_TO_CONFIRM)

  const register = useCallback(
    async (e: any) => {
      e.preventDefault()
      setIsLoading(true)
      const form = e.target
      const userData: UserRegistrationData = Object.fromEntries(
        new FormData(form).entries()
      ) as UserRegistrationData
      try {
        await signUp({
          username: userData.email,
          password: userData.password,
          options: {
            userAttributes: {
              preferred_username: userData.username,
              email: userData.email,
            },
          },
        })

        setEmailToConfirm(userData.email)
        return navigate(Pages.CONFIRM_EMAIL)
      } catch (error: any) {
        setIsLoading(false)
        setHasError(true)
        setErrorMessage(error.message)
      }
    },
    [navigate, setEmailToConfirm]
  )

  return (
    <LoginLayout>
      <Column
        lg={{ span: 4, offset: 2 }}
        md={{ span: 4, offset: 1 }}
        sm={{ span: 4, offset: 0 }}
        className="cds--p-05"
      >
        <InlineBanner
          isVisible={hasError}
          setIsVisible={setHasError}
          subtitle={errorMessage}
        />
        <h1 className="cds--type-semibold">CREATE AN ACCOUNT</h1>
        <Form aria-label="register form" onSubmit={register}>
          <Stack gap={5}>
            <TextInput
              labelText="User name"
              placeholder="User name"
              id="username"
              name="username"
            />
            <TextInput
              labelText="Email address"
              placeholder="Email address"
              id="email"
              name="email"
            />
            <TextInput.PasswordInput
              type="password"
              required
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
              labelText="Password"
              id="password"
              placeholder="password"
              name="password"
              helperText="Password must contain at least 8 characters, 1 uppercase letter, 1 lowercase letter and 1 number."
            />
          </Stack>
          <LoadingButton
            type="submit"
            className="btn-primary cds--mt-06"
            isLoading={isLoading}
            value="Create an account"
          />
        </Form>
        <h2 className="cds--type-semibold">Do you have an account?</h2>
        <Link to={Pages.LOGIN}>
          <Button kind="tertiary" className="btn-tertiary">
            Log in
          </Button>
        </Link>
      </Column>
    </LoginLayout>
  )
}
