export enum Pages {
  CHAT = '/chat',
  CONFIRM_EMAIL = '/confirm-email',
  EMAIL_CONFIRMED = '/email-confirmed',
  EXPLORE = '/explore',
  FORGOTTEN_PASSWORD = '/forgotten-password',
  LOGIN = '/',
  NEW_PASSWORD = '/new-password',
  PASSWORD_RESET = '/password-reset',
  PROJECT_DETAILS = '/project-finder',
  PROJECT_FINDER = '/project-finder',
  PROJECT_SEARCH = '/project-finder/search',
  REGISTER = '/register',
  WELCOME = '/welcome',
}
