import { Button, Column, Form, Grid, TextInput } from 'carbon-components-react'
import { Link, useNavigate } from 'react-router-dom'
import { fetchAuthSession, signIn } from 'aws-amplify/auth'
import { useEffect, useState } from 'react'

import { ErrorMessages } from 'constants/errorMessages'
import { InlineBanner } from 'components/inline-banner/InlineBanner'
import { LoadingButton } from 'components/loading-button/LoadingButton'
import { LoginLayout } from 'layouts/login-layout/LoginLayout'
import { Pages } from 'constants/pages'
import { Stack } from '@carbon/react'

export function Login() {
  const [hasError, setHasError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    ;(async () => {
      const token = (await fetchAuthSession()).tokens?.accessToken
      if (token !== undefined) navigate(Pages.WELCOME)
    })()
  })

  const login = async (e: any) => {
    e.preventDefault()
    setIsLoading(true)
    const form = e.target
    const formData = new FormData(form)
    const formJson = Object.fromEntries(formData.entries())
    try {
      const { isSignedIn } = await signIn({
        username: formJson.email as string,
        password: formJson.password as string,
      })

      if (isSignedIn) {
        return navigate(Pages.WELCOME)
      } else {
        setIsLoading(false)
        setHasError(true)
        setErrorMessage(ErrorMessages.GENERIC_ERROR)
      }
    } catch (error: any) {
      setIsLoading(false)
      setHasError(true)
      setErrorMessage(error.message)
    }
  }
  return (
    <LoginLayout>
      <Column
        lg={{ span: 4, offset: 2 }}
        md={{ span: 4, offset: 1 }}
        sm={{ span: 4, offset: 0 }}
        className="cds--p-05"
      >
        <InlineBanner
          isVisible={hasError}
          setIsVisible={setHasError}
          subtitle={errorMessage}
        />
        <h1 className="cds--type-semibold">LOG IN</h1>
        <Form aria-label="login form" onSubmit={login}>
          <Stack gap={5}>
            <TextInput
              labelText="Email address"
              placeholder="Email address"
              id="email"
              name="email"
            />
            <TextInput
              type="password"
              required
              labelText="Password"
              id="password"
              name="password"
              placeholder="password"
            />
            <Grid>
              <Column
                lg={{ span: 8, offset: 8 }}
                md={{ span: 4, offset: 8 }}
                sm={{ span: 4, offset: 8 }}
              >
                <Link
                  to={Pages.FORGOTTEN_PASSWORD}
                  className="cds--link-button"
                >
                  Forgot password
                </Link>
              </Column>
            </Grid>
          </Stack>
          <LoadingButton
            type="submit"
            className="btn-primary"
            value="Log in"
            isLoading={isLoading}
          />
        </Form>
        <h2 className="cds--type-semibold">Don't you have an account?</h2>
        <Link to={Pages.REGISTER}>
          <Button kind="tertiary" className="btn-tertiary">
            Create an account
          </Button>
        </Link>
      </Column>
    </LoginLayout>
  )
}
