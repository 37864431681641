import './ChatList.scss'

import { ActionableNotification, Stack, TextInput } from '@carbon/react'
import { FaCircleCheck, FaRegCopy } from 'react-icons/fa6'
import { Fragment, memo, useCallback, useEffect, useRef, useState } from 'react'
import {
  apiChatContent,
  apiChatMetadata,
  apiChatVersion,
  apiSubmitChat,
  apiSubmitNewChat,
} from 'services/apiService'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate, useOutletContext } from 'react-router-dom'

import { AuthContext } from 'middleware/protected-route/ProtectedRoute'
import { Chat } from '@carbon/icons-react'
import { ChatMessage } from '../chat-message/ChatMessage'
import { ChatMessageResponse } from 'models/responses'
import { Form } from 'carbon-components-react'
import { LoadingButton } from '../loading-button/LoadingButton'
import { LoadingMessage } from './LoadingMessage/LoadingMessage'
import { Pages } from 'constants/pages'
import { Params } from 'constants/params'
import { RaisedLogo } from '../raised-logo/RaisedLogo'
import { emptyField } from 'helpers/formHelper'
import { useCopyWithFormatting } from 'hooks/useCopyWithFormatting'
import useIntersectionObserver from 'hooks/useIntersectionObserver'
import { writeQueryString } from 'helpers/queryString'

const exampleGenericMessages = [
  'Summarise the typical diagnosis pathway for an oncology patient',
  'In projects focused on the oncology patient journey, which key stakeholders are typically interviewed?',
  'What type of healthcare professionals handle vascular conditions in a surgical setting?',
  'What factors influence an endocrinologist to become an early adopter of a new diabetes medication?',
]

const exampleProjectMessages = [
  'What insights on patient behaviour and healthcare professional practices have emerged?',
  'Summarise the main concerns of healthcare professionals and patients. How did they align with the initial hypotheses?',
  'What methodologies were used to understand patient experiences?',
  'Summarise the types and numbers of respondents.',
]

// export type ChatListProps = {}

let timer: ReturnType<typeof setTimeout> | undefined = undefined

export const ChatList = memo(() => {
  const queryClient = useQueryClient()
  const chatHistoryRef = useRef<HTMLDivElement>(null)
  const [onCopy, copied] = useCopyWithFormatting<HTMLDivElement>(chatHistoryRef)
  const queryParameters = new URLSearchParams(window.location.search)

  const projectId = queryParameters.get(Params.PROJECT_ID)
  const projectTitle = queryParameters.get(Params.PROJECT_TITLE)
  const chatParam = queryParameters.get(Params.CHAT) || ''
  const navigate = useNavigate()

  const { accessToken } = useOutletContext<AuthContext>()
  const [chatId, setChatId] = useState<string>(chatParam)
  const [isPolling, setPolling] = useState<Date | undefined>(new Date())
  const [messages, setMessages] = useState<ChatMessageResponse[] | undefined>()
  const [isDisabled, setIsDisabled] = useState(true)

  const messagesEndRef = useRef<HTMLDivElement>(null) // ref to the last message in the chat
  const isIntersecting = useIntersectionObserver(messagesEndRef) // checks is the messageEndRef is in view

  const { data: chatVersion } = useQuery({
    queryKey: ['chat_version', chatId, projectId],
    queryFn: () =>
      apiChatVersion({
        accessToken,
        chat_id: chatId,
      }),
    enabled: !!chatId && !!isPolling,
    refetchInterval: 500,
  })

  const { data: chatMetadata, dataUpdatedAt: metadataUpdatedAt } = useQuery({
    queryKey: ['chat_metadata', chatId, chatVersion?.data, projectId],
    queryFn: () => apiChatMetadata({ accessToken, chat_id: chatId }),
    enabled: !!chatId,
    refetchInterval: isPolling ? 2000 : undefined,
  })

  const {
    data: chatContent,
    isLoading,
    // isError,
  } = useQuery({
    queryKey: ['chat_content', chatId, chatVersion?.data, projectId],
    queryFn: () =>
      apiChatContent({
        accessToken,
        chat_id: chatId,
      }),
    enabled: !!chatId,
  })

  const submitNewChatMutation = useMutation({
    mutationFn: apiSubmitNewChat,
    onSuccess(data) {
      setPolling(new Date())
      const qs = writeQueryString({
        [Params.CHAT]: data.data,
        [Params.PROJECT_ID]: projectId,
        [Params.PROJECT_TITLE]: projectTitle,
      })
      navigate(`${Pages.CHAT}${qs}`)
      // refetch the previous chat history
      queryClient.refetchQueries({ queryKey: ['chatHistory'] })
    },
  })

  const submitExistingChatMutation = useMutation({
    mutationFn: apiSubmitChat,
    onSuccess() {
      setPolling(new Date())
    },
  })

  const scrollToBottomOfChat = useCallback(() => {
    // timeout used to move the action to the end of the event loop
    clearTimeout(timer)
    timer = setTimeout(
      () => messagesEndRef.current && messagesEndRef.current.scrollIntoView(),
      0
    )
  }, [messagesEndRef])

  useEffect(() => {
    if ((chatContent?.data.history || []).length >= (messages || []).length) {
      // if there are new messages or as many as there were before use the new
      // content from the server
      setMessages(chatContent?.data.history)
      if (isIntersecting) {
        // if the bottom div is in the viewport before the new messages are
        // added scroll to it once they are added
        scrollToBottomOfChat()
      }
    }
  }, [chatContent, isIntersecting, messages, scrollToBottomOfChat])

  useEffect(() => {
    if (
      isPolling &&
      isPolling?.getTime() < metadataUpdatedAt &&
      chatMetadata?.data.status === 'complete'
    ) {
      // checks that the complete signal is after the isPolling started again if
      // true then stops the polling
      setPolling(undefined)
    }
  }, [chatMetadata, metadataUpdatedAt, isPolling])

  const submitMessage = useCallback(
    async (messageText: string) => {
      emptyField('chat-box')
      const metadata = projectId ? { project_id: projectId } : undefined

      const newUserMessage: ChatMessageResponse = {
        chat_msg_id: `temp-${new Date().getTime()}`,
        message: messageText,
        role: 'user',
        created_at: `${new Date().getTime()}`,
        metadata: { sources: [] },
        supplement: {},
      }
      setMessages([...(messages || []), newUserMessage])
      scrollToBottomOfChat()

      if (!chatId) {
        submitNewChatMutation.mutate({
          accessToken,
          message: messageText,
          metadata,
        })
      } else {
        submitExistingChatMutation.mutate({
          accessToken,
          chat_id: chatId,
          message: messageText,
          metadata,
        })
      }
    },
    [
      accessToken,
      chatId,
      projectId,
      submitExistingChatMutation,
      submitNewChatMutation,
      messages,
      scrollToBottomOfChat,
    ]
  )

  const submitForm = useCallback(
    async (e: any) => {
      e.preventDefault()
      const formData = new FormData(e.target)
      const messageText = formData.get('message')?.toString()

      if (!messageText) {
        return
      }

      await submitMessage(messageText)
    },
    [submitMessage]
  )

  useEffect(() => {
    // Update local chatId state if `existingChatId` prop changes
    if (chatParam !== chatId) {
      setChatId(chatParam)
      if (chatId) {
        setMessages(undefined)
      }
    }
  }, [chatId, chatParam])

  return (
    <>
      {!!messages?.length && messages.length > 0 && (
        <button className="export-chat no-copy" onClick={onCopy}>
          {copied ? (
            <>
              <FaCircleCheck size={16} style={{ color: 'green' }} />
              <span style={{ color: 'black' }}>Copied</span>
            </>
          ) : (
            <>
              <FaRegCopy size={16} />
              Copy chat
            </>
          )}
        </button>
      )}
      <div className="chat-wrapper">
        <div className="chat-container cds--pb-10" ref={chatHistoryRef}>
          {projectId ? (
            <ActionableNotification
              inline
              aria-label="closes notification"
              kind="info"
              hideCloseButton
              statusIconDescription="notification"
              title="Project specific chat: "
              subtitle={`Project ${projectId}`}
              actionButtonLabel="View project"
              onActionButtonClick={() =>
                navigate(`${Pages.PROJECT_DETAILS}/${projectId}`)
              }
              lowContrast={true}
              hasFocus={false}
            />
          ) : null}

          {!messages && !isLoading && (
            <div>
              <br />
              <RaisedLogo />
              <h2>
                How can I help you today?{' '}
                {projectId ? (
                  <b>
                    {' '}
                    Project {projectId} - {projectTitle}
                  </b>
                ) : null}
              </h2>
              <div className="cds--pt-10">
                <Stack orientation="horizontal" gap={4}>
                  <Chat size="24" />
                  <span>Example messages</span>
                </Stack>
                <div className="cds--mt-03">
                  {projectId
                    ? exampleProjectMessages.map((message, i) => (
                        <button
                          className="chat-msg-example cds--mt-05"
                          key={i}
                          onClick={() => submitMessage(message)}
                        >
                          {message}
                        </button>
                      ))
                    : exampleGenericMessages.map((message, i) => (
                        <button
                          className="chat-msg-example cds--mt-05"
                          key={i}
                          onClick={() => submitMessage(message)}
                        >
                          {message}
                        </button>
                      ))}
                </div>
              </div>
            </div>
          )}

          {messages?.map((msg: ChatMessageResponse, index) => {
            const lastMessage = messages.length - 1 === index
            return (
              <Fragment key={msg.chat_msg_id}>
                <ChatMessage
                  {...msg}
                  status={
                    index === messages.length - 1
                      ? chatMetadata?.data.status
                      : 'complete'
                  }
                  onTyping={() => {
                    isIntersecting && scrollToBottomOfChat()
                  }}
                  lastMessage={lastMessage}
                />
                {lastMessage && msg.role === 'user' && <LoadingMessage />}
              </Fragment>
            )
          })}
          <div ref={messagesEndRef} />
        </div>
        <div
          style={{
            position: 'sticky',
            bottom: 0,
            paddingTop: '4px',
            backgroundColor: 'white',
          }}
        >
          <Form onSubmit={submitForm} className="send-message-form">
            <TextInput
              id="chat-box"
              name="message"
              type="text"
              labelText=""
              placeholder="Message metAInsights"
              helperText="metAInsights can make mistakes. Consider checking important information."
              onChange={(e: any) =>
                setIsDisabled(e.target.value?.trim() === '')
              }
            />
            <div>
              <LoadingButton
                size="md"
                type="submit"
                value="Send"
                isLoading={false}
                isDisabled={isDisabled}
              />
            </div>
          </Form>
        </div>
      </div>
    </>
  )
})
