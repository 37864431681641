import { RefObject, useCallback, useState } from 'react'

import { logger } from '../utils/logger'

const log = logger.child({ file: 'frontend/src/hooks/useCopy.ts' })

export const useCopyWithFormatting = <T extends HTMLElement>(
  ref: RefObject<T>
): [() => Promise<void>, boolean] => {
  const [copied, setCopied] = useState(false)

  const onCopy = useCallback(async () => {
    const current = ref.current
    if (!current) {
      log.debug({ msg: 'no current ref' })
      return
    }

    try {
      // Function to remove certain elements and preserve the formatting by converting inner HTML to text with newlines
      const getFormattedText = (element: HTMLElement): string => {
        // Clone the element so we can modify it without affecting the original DOM
        const clone = element.cloneNode(true) as HTMLElement

        // Remove unwanted elements (like buttons, inputs, etc.)
        const elementsToExclude = clone.querySelectorAll(
          'input, textarea,.no-copy, [role="tooltip"]'
        )
        elementsToExclude.forEach((el) => el.remove())

        let text = clone.innerHTML
          // Replace <br> tags with newlines
          .replace(/<br\s*\/?>/gi, '\n')
          // Replace block elements with newlines
          .replace(/<\/p>/gi, '\n')
          .replace(/<\/div>/gi, '\n')
          .replace(/<\/small>/gi, '\n')
          .replace(/<\/li>/gi, '\n')
          .replace(/<\/h[1-6]>/gi, '\n') // For headings (h1-h6)

        // Strip out remaining HTML tags
        const tempDiv = document.createElement('div')
        tempDiv.innerHTML = text
        let formattedText = tempDiv.textContent || tempDiv.innerText || ''

        // Ensure no more than 2 consecutive newlines
        formattedText = formattedText.replace(/\n{3,}/g, '\n\n')

        return formattedText
      }

      // Get the formatted text content from the element, excluding unwanted elements
      const textToCopy = getFormattedText(current)

      // Use the Clipboard API to copy the text
      await navigator.clipboard.writeText(textToCopy)
      log.debug({
        msg: 'Text copied to clipboard with formatting, excluding certain elements',
        textToCopy,
      })
      setCopied(true)
      setTimeout(() => setCopied(false), 1000)
    } catch (err) {
      log.error({ msg: 'Failed to copy text to clipboard', err })
    }
  }, [ref])

  return [onCopy, copied]
}
