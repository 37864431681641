import { RefObject, useEffect, useState } from 'react'

interface IntersectionObserverArgs {
  /**
   * If the container you want to view against is not the document
   */
  root?: Element | null
  /**
   * This decreases the viewport for observing can be in pixels of % and can accept up to Accepts
   * up to four space-separated values, following the CSS margin convention (top, right, bottom, left)
   */
  rootMargin?: string
  /**
   * A single number between 0.0 and 1.0 or an array of numbers
   * - 0.0: The callback is triggered as soon as even one pixel is visible
   * - 1.0: The callback is triggered only when the entire element is visible
   * An array of thresholds can be used to trigger the callback at multiple points of visibility.
   */
  threshold?: number | number[]
}

/**
 * hook to inform whether a referenced object is visible on the screen.
 * @param ref the React ref to the element in the dom
 * @param options the intersection observer options
 * @returns boolean
 */
const useIntersectionObserver = (
  ref: RefObject<Element>,
  {
    root = null,
    rootMargin = '0px',
    threshold = 0,
  }: IntersectionObserverArgs = {}
): boolean => {
  const [isIntersecting, setIsIntersecting] = useState(false)

  useEffect(() => {
    const element = ref.current
    if (!element) return

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setIsIntersecting(entry.isIntersecting)
        })
      },
      {
        root,
        rootMargin,
        threshold,
      }
    )

    observer.observe(element)

    return () => {
      observer.disconnect()
    }
  }, [ref, root, rootMargin, threshold])

  return isIntersecting
}

export default useIntersectionObserver
