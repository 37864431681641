import './FilterBar.scss'

import {
  Button,
  FilterableMultiSelect,
  FilterableMultiSelectProps,
} from 'carbon-components-react'
import { hasIntersection, isNotEmpty } from 'helpers/formattingHelper'

import { Params } from 'constants/params'
import { ProjectData } from 'models/responses'
import { useCallback } from 'react'
import { useSessionStorage } from 'hooks/useSessionStorage'

export type FilterBarProps = {
  projects: ProjectData[] | undefined
  unfilteredProjects: ProjectData[] | undefined
  isSavedSearch?: boolean
  updateProjects: (projects: ProjectData[]) => void
  resetFilters: () => void
}

type Filter =
  | 'client'
  | 'project_year'
  | 'project_lead'
  | 'country_markets'
  | 'methodologies'
  | 'therapy_area'
  | 'program_type'

export type FiltersState = Record<
  Filter,
  { key: string | number; value: boolean }[]
>

export const initFilters = (
  projects: ProjectData[],
  lastFilters?: FiltersState
) => {
  const emptyState: FiltersState = {
    client: [],
    project_year: [],
    project_lead: [],
    country_markets: [],
    methodologies: [],
    therapy_area: [],
    program_type: [],
  }
  const stateKeys = Object.keys(emptyState)
  return projects?.reduce((state: FiltersState, project: ProjectData) => {
    for (let i = 0; i < stateKeys.length; i++) {
      if (isNotEmpty((project.metadata as any)[stateKeys[i]])) {
        const value = (project.metadata as any)[stateKeys[i]]
        const currentFilterState = (state as any)[stateKeys[i]] as {
          key: string
          value: boolean
        }[]
        if (Array.isArray(value)) {
          for (let j = 0; j < value.length; j++) {
            if (
              !currentFilterState
                .map((element) => element.key)
                .includes(value[j])
            ) {
              const isActivatedValue = lastFilters
                ? (lastFilters as any)[stateKeys[i] as any].filter(
                    (item: any) => item.key === value[j] && item.value === true
                  ).length > 0
                : false
              currentFilterState.push({
                key: value[j],
                value: isActivatedValue,
              })
            }
          }
        } else {
          if (
            !currentFilterState.map((element) => element.key).includes(value)
          ) {
            const isActivatedValue = lastFilters
              ? (lastFilters as any)[stateKeys[i] as any].filter(
                  (item: any) => item.key === value && item.value === true
                ).length > 0
              : false
            currentFilterState.push({ key: value, value: isActivatedValue })
          }
        }
      }
    }
    return state
  }, emptyState)
}

export function FilterBar({
  projects,
  updateProjects,
  resetFilters,
  unfilteredProjects,
  isSavedSearch,
}: FilterBarProps) {
  const [filtersState, setFiltersState] = useSessionStorage<FiltersState>(
    Params.CURRENT_FILTERS,
    initFilters(unfilteredProjects as ProjectData[])
  )

  const runFilter = useCallback(
    (filter: Filter, e: any) => {
      const currentFilters = {
        ...filtersState,
        [filter]: (filtersState as any)[filter].map((element: any) => {
          if (
            e.selectedItems.map((item: any) => item.id).includes(element.key)
          ) {
            element.value = true
          } else {
            element.value = false
          }
          return element
        }),
      } as FiltersState
      const appliedFilters = Object.entries(currentFilters as any).reduce(
        (filters: any, [key, value]) => {
          const activatedFilters = (value as any)?.filter(
            (element: any) => element.value === true
          )
          if (activatedFilters.length > 0) {
            filters[key] = activatedFilters.map((element: any) => element.key)
          }
          return filters
        },
        {}
      )

      if (Object.keys(appliedFilters).length === 0) {
        const newFilters = initFilters(unfilteredProjects as ProjectData[])
        setFiltersState(newFilters)
        if (unfilteredProjects) {
          updateProjects(unfilteredProjects)
        }
      } else {
        const filteredProjects = unfilteredProjects?.filter((project) => {
          return Object.entries(project.metadata).every(([key, value]) => {
            const correspondingFilter = (appliedFilters as any)[key as any]
            if (!correspondingFilter) return true
            return Array.isArray(value)
              ? hasIntersection(value, correspondingFilter)
              : correspondingFilter.includes(value)
          })
        }) as ProjectData[]
        updateProjects(filteredProjects)
        const newFilters = initFilters(
          unfilteredProjects as ProjectData[],
          currentFilters
        )

        setFiltersState(newFilters)
      }
    },
    [filtersState, setFiltersState, unfilteredProjects, updateProjects]
  )

  const getFilterProps = useCallback(
    (filter: Filter, titleText: string): FilterableMultiSelectProps => {
      if (
        !filtersState ||
        !filtersState[filter] ||
        filtersState[filter].length === 0
      ) {
        return {
          id: filter,
          titleText,
          items: [],
          placeholder: 'Select',
          disabled: true,
          onChange: () => {},
        }
      }
      const items: { id: string; text: string }[] = (filtersState as any)[
        filter
      ].map((element: any) => {
        return { id: element.key, text: `${element.key}` }
      })
      return {
        id: filter,
        titleText,
        items,
        placeholder: 'Select',
        itemToString: (item: any) => (item ? item.text : ''),
        selectionFeedback: 'top-after-reopen',
        onChange: (e: any) => runFilter(filter, e),
        initialSelectedItems: isSavedSearch
          ? (filtersState as any)[filter]
              .filter((element: any) => element.value === true)
              .map((element: any) => {
                return { id: element.key, text: `${element.key}` }
              })
          : [],
      } as any
    },
    [filtersState, isSavedSearch, runFilter]
  )

  return (
    <div className="filter-bar-wrapper">
      {!!filtersState && (
        //  Key added to refresh the FilterableMultiSelect component's initial state when a new search is performed
        <div className="filter-bar" key={`${isSavedSearch}`}>
          <h5 style={{ marginBottom: 0 }}>Filters</h5>
          <FilterableMultiSelect {...getFilterProps('client', 'Client')} />
          <FilterableMultiSelect {...getFilterProps('project_year', 'Year')} />
          <FilterableMultiSelect
            {...getFilterProps('project_lead', 'Project member')}
          />
          <FilterableMultiSelect
            {...getFilterProps('country_markets', 'Countries')}
          />
          <FilterableMultiSelect
            {...getFilterProps('methodologies', 'Methodology')}
          />
          <FilterableMultiSelect
            {...getFilterProps('therapy_area', 'Therapy')}
          />
          <FilterableMultiSelect
            {...getFilterProps('program_type', 'Program type')}
          />
          {projects?.length === 0 ? (
            <Button onClick={resetFilters} kind="ghost">
              Reset filters
            </Button>
          ) : null}
        </div>
      )}
    </div>
  )
}
