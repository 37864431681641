export const toFirstLetterUpperCase = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1)
}

export const prettifyKey = (key: string) => {
  switch (key) {
    case 'country_markets':
      return 'Country/markets'
    case 'total_respondant_count':
      return 'Total respondent count'
    case 'respondant_type':
      return 'Respondent type'
    default:
      return toFirstLetterUpperCase(key.replaceAll('_', ' '))
  }
}

export const isNotEmpty = (value: any) => {
  if (!value) return false
  if (typeof value === 'number') return true
  if (typeof value === 'string') return value.trim().length > 0
  return value.length > 0
}

export const hasIntersection = (array1: any[], array2: any[]) => {
  return array1.filter((value) => array2.includes(value)).length > 0
}
