import { Outlet, useNavigate } from 'react-router-dom'
import { fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth'
import { useEffect, useState } from 'react'

import { Pages } from 'constants/pages'

export type AuthContext = {
  accessToken: string
  username: string
}

export function ProtectedRoute() {
  const navigate = useNavigate()
  const [accessToken, setAccessToken] = useState('')
  const [username, setUsername] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    ;(async () => {
      try {
        const token = (await fetchAuthSession()).tokens?.accessToken
        if (!token) navigate(Pages.LOGIN)

        const name = (await fetchUserAttributes()).preferred_username
        setAccessToken(token?.toString() as string)
        setUsername(name as string)
        setIsLoading(false)
      } catch (err) {
        navigate(Pages.LOGIN)
      }
    })()
  }, [navigate])

  return isLoading ? null : <Outlet context={{ accessToken, username }} />
}
