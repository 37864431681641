import './ProjectFinder.scss'

import { Form, Search } from 'carbon-components-react'
import { useEffect, useState } from 'react'

import { Footer } from 'components/footer/Footer'
import { HeaderSideBarLayout } from 'layouts/HeaderSideBarLayout/HeaderSideBarLayout'
import { LoadingButton } from 'components/loading-button/LoadingButton'
import { Pages } from 'constants/pages'
import { Params } from 'constants/params'
import { useNavigate } from 'react-router-dom'
import { useSessionStorage } from 'hooks/useSessionStorage'

export function ProjectFinder() {
  const [isDisabled, setIsDisabled] = useState(true)
  const [, setCurrentSearch] = useSessionStorage(Params.CURRENT_SEARCH, '')
  const [, setFilters] = useSessionStorage(Params.CURRENT_FILTERS)
  const [, setUnfilteredSearchResults] = useSessionStorage(
    Params.UNFILTERED_SEARCH_RESULTS
  )
  const [, setCurrentSearchResults] = useSessionStorage(
    Params.CURRENT_SEARCH_RESULTS
  )
  const navigate = useNavigate()
  const searchProject = (e: any) => {
    e.preventDefault()
    const form = e.target
    const { searchTerm } = Object.fromEntries(new FormData(form).entries())
    navigate(`${Pages.PROJECT_SEARCH}?${Params.SEARCH}=${searchTerm}`)
  }

  useEffect(() => {
    setCurrentSearch()
    setFilters()
    setCurrentSearchResults()
    setUnfilteredSearchResults()
  }, [
    setCurrentSearch,
    setFilters,
    setCurrentSearchResults,
    setUnfilteredSearchResults,
  ])

  return (
    <HeaderSideBarLayout>
      <div className="project-finder-container">
        <h1 className="cds--type-semibold">Project Finder</h1>
        <p>
          Project Finder helps you to access relevant information from previous
          projects based on various criteria including client, project member,
          methodology, and therapy.
        </p>
        <Form onSubmit={searchProject} className="search-form cds--mt-07">
          <Search
            size="lg"
            className=""
            placeholder="Search"
            labelText="Search"
            closeButtonLabelText="Clear search"
            id="search-project"
            name="searchTerm"
            onChange={(e: any) => setIsDisabled(e.target.value?.trim() === '')}
          />
          <LoadingButton
            type="submit"
            className="btn-primary"
            value="Search"
            isLoading={false}
            isDisabled={isDisabled}
          />
        </Form>
      </div>
      <Footer />
    </HeaderSideBarLayout>
  )
}
