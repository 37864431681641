import { Breadcrumb, BreadcrumbItem, Loading } from 'carbon-components-react'
import { FinderResponse, ProjectData } from 'models/responses'
import { Link, useOutletContext, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'

import { AuthContext } from 'middleware/protected-route/ProtectedRoute'
import { Footer } from 'components/footer/Footer'
import { Header } from 'components/header/Header'
import { Pages } from 'constants/pages'
import { Project } from 'components/project/Project'
import { searchProjects } from 'services/apiService'
import styles from './projectDetails.module.css'

export function ProjectDetails() {
  const [project, setProject] = useState<ProjectData | undefined>()
  const [isLoading, setIsLoading] = useState(true)
  const { projectId } = useParams()
  const { accessToken } = useOutletContext<AuthContext>()

  useEffect(() => {
    if (accessToken) {
      searchProjects({
        accessToken,
        metadata: { project_id: projectId as string },
        search_scope: ['generated_content'],
      })
        .then(({ data }: { data: FinderResponse }) => {
          setProject(data.hits[0])
          setIsLoading(false)
        })
        .catch((error) => {
          setIsLoading(false)
        })
    }
  }, [accessToken, projectId])

  return (
    <>
      <Header displayTabs={true} displayTitle={true} />
      <div className={styles.container}>
        <Breadcrumb noTrailingSlash>
          <BreadcrumbItem>
            <Link to={Pages.PROJECT_FINDER}>Project finder</Link>
          </BreadcrumbItem>

          <BreadcrumbItem
            isCurrentPage
            href={`${Pages.PROJECT_DETAILS}/${projectId}`}
          >
            <Link
              className="text-primary"
              to={`${Pages.PROJECT_DETAILS}/${projectId}`}
            >
              Project {projectId}
            </Link>
          </BreadcrumbItem>
        </Breadcrumb>

        {isLoading ? (
          <Loading />
        ) : (
          <Project project={project as ProjectData} withDetails={true} />
        )}

        <Footer />
      </div>
    </>
  )
}
