import './Project.scss'

import { Button } from 'carbon-components-react'
import { Link } from 'react-router-dom'
import { MetadataTags } from '../MetadataTags/MetadataTags'
import { Pages } from 'constants/pages'
import { Params } from 'constants/params'
import { ProjectData } from 'models/responses'
import { RaisedLogo } from '../raised-logo/RaisedLogo'
import { Stack } from '@carbon/react'

export type ProjectProps = {
  popupView?: boolean
  project: ProjectData
  withDetails?: boolean
}

export function Project({ project, withDetails, popupView }: ProjectProps) {
  if (!project) return <div>No project found</div>

  return (
    <div
      className={`${
        withDetails || popupView ? 'project-details' : 'project'
      } cds--mb-04`}
    >
      <div className="display-flex justify-content-space-between">
        <MetadataTags metadata={project.metadata} />

        {popupView ? (
          ''
        ) : (
          <div>
            <Link
              to={`${Pages.CHAT}?${Params.PROJECT_ID}=${project.project_id}&${Params.PROJECT_TITLE}=${project.metadata.title}`}
            >
              <Button
                kind="tertiary"
                style={{ lineHeight: '2rem' }}
                size="sm"
                className="cds--pr-05"
              >
                <Stack orientation="horizontal" gap={3}>
                  Ask questions in chat <RaisedLogo size="2rem" />
                </Stack>
              </Button>
            </Link>
          </div>
        )}
      </div>
      <div>
        {withDetails ? (
          <h2 className="cds--type-semibold text-primary">
            Project {project.project_id} - {project.metadata.title}
          </h2>
        ) : (
          <Link
            className="cds--link-button"
            to={`${Pages.PROJECT_DETAILS}/${project.project_id}`}
          >
            <h2 className="cds--type-semibold text-primary">
              Project {project.project_id} - {project.metadata.title}
            </h2>
          </Link>
        )}
        <div className="project-description">
          <div>
            <div>
              <span className="cds--type-semibold">Objectives:</span>
              <br />
              <ul>
                {project.generated_content.objectives?.map((content) => (
                  <li key={content}>{content}</li>
                ))}
              </ul>
            </div>
            <div>
              <span className="cds--type-semibold">Summary:</span>
              <br />
              <ul>
                {project.generated_content.summary?.map((content) => (
                  <li key={content}>{content}</li>
                ))}
              </ul>
            </div>
            {withDetails ? (
              <div>
                <span className="cds--type-semibold">Key insights:</span>
                <br />
                <ul>
                  {project.generated_content.key_insights
                    ? project.generated_content.key_insights?.map((content) => (
                        <li key={content}>{content}</li>
                      ))
                    : project.generated_content.key_outcomes?.map((content) => (
                        <li key={content}>{content}</li>
                      ))}
                </ul>
              </div>
            ) : (
              ''
            )}
            {popupView ? (
              <Link
                to={`${Pages.PROJECT_DETAILS}/${project.project_id}`}
                className="text-primary"
              >
                Go to project details page
              </Link>
            ) : (
              ''
            )}
          </div>
          {/*withDetails ? <div>
                    <ToastNotification className="toast-project-documents cds--pb-04" kind="info" role="log" lowContrast={true} hideCloseButton={true} timeout={0} title="Documents in sharepoint" subtitle="You can view the documents in sharepoint by clicking them">
                        <p className="text-primary">Project folder</p>
                        <p className="text-primary">Final report</p>
                        <p className="text-primary">Discussion guide</p>
                        <p className="text-primary">Content analysis</p>
                        <p className="text-primary">Interviews transcripts</p>
                    </ToastNotification>
                    </div> : ''*/}
        </div>
        {/*withDetails ? '' :<Link to="#" className="text-primary">
                View the project in sharepoint <Launch size="24"></Launch>
                </Link>*/}
      </div>
    </div>
  )
}
