import './SideBar.scss'

import { ChevronLeft, ChevronRight } from '@carbon/icons-react'
import { PropsWithChildren, useState } from 'react'

import { Logo } from 'components/Logo/Logo'

export type SideBarProps = PropsWithChildren<{ showLogo?: boolean }>

export const SideBar = ({ children, showLogo }: SideBarProps) => {
  const [isOpen, setIsOpen] = useState(true)

  return (
    <div className={`side-bar ${isOpen ? '' : 'closed'}`}>
      {showLogo && (
        <div style={{ alignSelf: 'center' }}>
          <Logo />
        </div>
      )}
      {children}
      <div className="padding" />
      <button className="side-bar-toggle" onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? <ChevronLeft size={20} /> : <ChevronRight size={20} />}
      </button>
    </div>
  )
}
