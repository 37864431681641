import { InlineNotification } from 'carbon-components-react'

export type InlineBannerProps = {
  isVisible: boolean
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
  subtitle: string
  hideCloseButton?: boolean
}
export function InlineBanner({
  isVisible,
  setIsVisible,
  subtitle,
  hideCloseButton = false,
}: InlineBannerProps) {
  return isVisible ? (
    <InlineNotification
      className="cds--mb-04"
      kind="error"
      lowContrast={true}
      role="log"
      title="Error"
      subtitle={subtitle}
      hideCloseButton={hideCloseButton}
      onCloseButtonClick={() => setIsVisible(false)}
    />
  ) : null
}
