import { Column, Loading } from 'carbon-components-react'

import { LoginLayout } from 'layouts/login-layout/LoginLayout'
import { Pages } from 'constants/pages'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export type RedirectLayoutProps = {
  text: string
}
export function RedirectLayout({ text }: RedirectLayoutProps) {
  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => {
      navigate(Pages.LOGIN)
    }, 3000)
  })

  return (
    <LoginLayout>
      <Column
        lg={{ span: 4, offset: 2 }}
        md={{ span: 4, offset: 1 }}
        sm={{ span: 4, offset: 0 }}
        className="cds--mt-10 cds--mb-10 cds--p-05"
      >
        <h1 className="cds--type-semibold">{text}</h1>
        <p>We're redirecting you to the homepage...</p>
        <Loading withOverlay={false} small={true} />
      </Column>
    </LoginLayout>
  )
}
