import './LinkButton.scss'

import React, { PropsWithChildren } from 'react'

export type LinkButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  PropsWithChildren<{}>

/**
 * Button styles like a link
 */
export const LinkButton = ({
  children,
  type = 'button',
  ...props
}: LinkButtonProps) => {
  return (
    <button className="text-primary link-button" type={type} {...props}>
      {children}
    </button>
  )
}
