import { ProjectMetaData } from 'models/responses'
import { Tag } from 'carbon-components-react'
import { Tooltip } from '@carbon/react'
import { prettifyKey } from 'helpers/formattingHelper'
import { useMemo } from 'react'

export type MetadataTagsProps = {
  metadata: ProjectMetaData
  tagsToInclude?: Array<keyof ProjectMetaData>
}

const defaultTagInclusionList: Array<keyof ProjectMetaData> = [
  'client',
  'project_year',
  'project_lead',
  'country_markets',
  'program_type',
  'therapy_area',
  'methodologies',
  'respondent_type',
  'total_number_respondents',

  'total_number_respondants',
  'respondant_type',
]

export function filterTagInclusionList(
  [key, value]: [string, string | number | string[]],
  tagInclusionList: string[] = defaultTagInclusionList
) {
  return (
    !(!value || (Array.isArray(value) && value.length === 0)) &&
    tagInclusionList.includes(key)
  )
}

export const MetadataTags = ({
  metadata,
  tagsToInclude = defaultTagInclusionList,
}: MetadataTagsProps) => {
  const tags = useMemo(
    () =>
      Object.entries(metadata).filter((p) =>
        filterTagInclusionList(p, tagsToInclude)
      ),
    [metadata, tagsToInclude]
  )
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <h2 style={{ display: 'none' }} className="no-copy">
        Tags
      </h2>
      {tags.map(([key, value]: [string, string | number | string[]]) => {
        const tooltip = prettifyKey(key)
        return (
          <div key={key}>
            <Tooltip label={tooltip} enterDelayMs={100} leaveDelayMs={0}>
              <Tag
                type="blue"
                className="cds--mr-04"
                style={{ paddingTop: '2px' }}
              >
                <span style={{ display: 'none' }}>{tooltip}: </span>
                <span>{Array.isArray(value) ? value.join(', ') : value}</span>
              </Tag>
            </Tooltip>
          </div>
        )
      })}
    </div>
  )
}
