import QueryString from 'qs'

export const writeQueryString = (
  objectToQS: Record<string, any>,
  options: QueryString.IStringifyOptions = {}
) =>
  QueryString.stringify(objectToQS, {
    arrayFormat: 'brackets',
    encodeValuesOnly: true,
    addQueryPrefix: true,
    ...options,
  })

export const parseQueryString = <T extends object>(
  stringToParse: string,
  options: QueryString.IParseOptions = {}
): T => QueryString.parse(stringToParse, options) as T
