import { Column, Grid } from 'carbon-components-react'

import { Footer } from 'components/footer/Footer'
import { Header } from 'components/header/Header'

export function Explore() {
  return (
    <>
      <Header displayTabs={true} displayTitle={true} />
      <Grid>
        <Column
          lg={{ span: 10, offset: 3 }}
          md={{ span: 6, offset: 1 }}
          sm={{ span: 4, offset: 0 }}
        >
          <h1 className="cds--type-semibold">Help & Support Centre</h1>
          <p>
            Welcome to the metAInsights Support Centre. Here you will find
            guidance on optimising your use of metAInsights, understanding its
            functionalities, and interacting effectively.
          </p>
          <p className="cds--type-semibold">Have you encountered an issue?</p>
          <p>
            Please use this{' '}
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSeea8clYnyV02-D-5t8-5zoEAJUALrr7zQ0pLdQqbzVg5NmrA/viewform?usp=sf_link"
              target="_blank"
              rel="noopener noreferrer"
            >
              Report Form
            </a>{' '}
            and a member of our team will investigate further.
          </p>
          <p className="cds--type-semibold">How can I provide feedback?</p>
          <p>
            Your insights help to improve the tool. Please use this{' '}
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSdFWX6NZv2jIsvAi_PyDGOvWQacFAHNiVoBGnQZ1z9ntUTFUg/viewform?usp=sf_link"
              target="_blank"
              rel="noopener noreferrer"
            >
              Feedback Form
            </a>{' '}
            to share your feedback and suggestions for improvements.
          </p>
          <p className="cds--type-semibold">What is metAInsights?</p>
          <p>
            metAInsights is a tool that leverages artificial intelligence (AI)
            to summarise and surface insights from Branding Science’s extensive
            healthcare insights knowledge base. Using a Large Language Model
            (LLM), metAInsights has been trained on more than a thousand
            Branding Science projects, enabling it to understand and generate
            human-like text responses based on your queries.
          </p>
          <p className="cds--type-semibold">How does it work?</p>
          <span>metAInsights has two primary features: </span>
          <ol>
            <li>
              <b>Project Finder:</b> Use keyword searches to locate related
              projects and documents.
            </li>
            <li>
              <b>Chat:</b> Engage with the chatbot with queries relating to the
              Branding Science knowledge base.
            </li>
          </ol>
          <p className="cds--type-semibold">
            How to write effective queries for metAInsights?
          </p>
          <span>
            For optimal interactions with the metAInsights tool, follow these
            guidelines to ensure your queries generate relevant responses:{' '}
          </span>
          <ol>
            <li>
              <b>Strive for clarity and precision.</b> The more specific you
              are, the more accurate and relevant the response will be.
            </li>
            <li>
              <b>Use relevant terminology and project-specific language.</b> For
              instance, if discussing drug development strategies, use specific
              terms like "clinical trial phase advancements" or "regulatory
              approval pathways".
            </li>
            <li>
              <b>Iterate and refine.</b>This iterative process can help narrow
              down the focus to obtain more detailed insights. If a question is
              too broad, consider splitting it into more focused, smaller
              questions.
            </li>
            <li>
              <b>Request summaries or comparative analyses when appropriate,</b>{' '}
              such as "Compare market penetration strategies for biologics
              versus small molecule drugs". This approach can provide a broad
              overview or highlight differences in a clear, concise manner.
            </li>
            <li>
              <b>Highlight with quotation marks.</b> Enclose keywords or
              critical phrases in quotation marks to highlight them. This
              technique can help the model recognise the central elements of
              your query.
            </li>
            <li>
              <b>Avoid negative terms.</b> Refrain from using negative terms or
              double negatives (e.g., "not asthma"). These can confuse the model
              and lead to less accurate results.
            </li>
            <li>
              <b>Consider conversational limits.</b> Be aware that the model may
              not recall details from previous interactions after a certain
              number of exchanges, typically around six messages. This
              limitation means it might seem to "forget" earlier parts of the
              conversation, so you may need to restate or summarise key points.
            </li>
          </ol>

          <p className="cds--type-semibold">
            What are the data sources for metAInsights?
          </p>
          <p>
            Training on projects with completion dates between May 2015 and
            February 2024, metAInsights is based on insights extracted from the
            final project reports and metadata from SharePoint.
          </p>
          <p>
            While information is primarily based on Branding Science data, it
            should be noted that responses may also draw on pre-training
            knowledge to understand and respond to queries effectively. This
            means that the responses provided may not be exclusively derived
            from Branding Science data.
          </p>
          <p className="cds--type-semibold">
            Can I see my previous chat history?
          </p>
          <p>
            Yes. To review your own chat history, click on the grey bar on the
            left-hand side of the chat page.
          </p>
          <p className="cds--type-semibold">Who can view my conversations?</p>
          <p>
            All interactions are securely stored. For access to logs, contact
            metAInsightSupport@branding-science.com.
          </p>
          <p className="cds--type-semibold">What do we mean by assets?</p>
          <span>
            The data utilized in metAInsights are referred to as assets. The
            current version of metAInsights includes several types of assets:
          </span>
          <ol>
            <li>
              <b>Slides:</b> Extracted from project final reports.
            </li>
            <li>
              <b>Content Analysis:</b> Insights derived from content analysis
              documents.
            </li>
            <li>
              <b>Generated Content:</b> AI-generated project summaries.
            </li>
          </ol>
          <p className="cds--type-semibold">
            How is project relevance calculated?
          </p>
          <p>
            The Project Finder helps find the best matches through a combination
            of keyword match comparison and content similarity between the
            search terms and the project content.
          </p>
          <p>
            To improve the relevance of the search results, use filters to
            narrow down the returned projects. Note that these filters use
            information from Branding Science SharePoint, supplemented with some
            automatically generated details.
          </p>
          <p className="cds--type-semibold">
            What time point does the date refer to?
          </p>
          <p>
            The date presented in the tool will refer to the year that the
            project folder was created in Sharepoint.
          </p>
        </Column>
      </Grid>
      <Footer />
    </>
  )
}
