export enum Params {
  CHAT = 'chat',
  CURRENT_CHAT = 'currentChat',
  CURRENT_FILTERS = 'currentFilters',
  CURRENT_SEARCH = 'currentSearch',
  CURRENT_SEARCH_RESULTS = 'currentSearchResults',
  CURRENT_SORTING = 'currentSorting',
  EMAIL_FOR_RESET = 'emailForReset',
  EMAIL_TO_CONFIRM = 'emailToConfirm',
  PROJECT_ID = 'projectId',
  PROJECT_TITLE = 'projectTitle',
  SEARCH = 'search',
  UNFILTERED_SEARCH_RESULTS = 'unfilteredSearchResults',
}
