import { PropsWithChildren, ReactNode } from 'react'

import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary'
import { Header } from 'components/header/Header'
import { SideBar } from 'components/side-bar/SideBar'
import styles from './headerSideBarLayout.module.css'

export type HeaderSideBarLayoutProps = PropsWithChildren<{
  sideBar?: ReactNode
}>

export const HeaderSideBarLayout = ({
  children,
  sideBar,
}: HeaderSideBarLayoutProps) => {
  return (
    <div className={styles.layout}>
      {sideBar && (
        <SideBar showLogo>
          <ErrorBoundary>{sideBar}</ErrorBoundary>
        </SideBar>
      )}

      <Header displayTitle={!sideBar} displayTabs />
      <main
        style={{
          gridArea: 'main',
          overflowY: 'scroll',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {children}
      </main>
    </div>
  )
}
