import React, { PropsWithChildren } from 'react'

import { RaisedLogo } from 'components/raised-logo/RaisedLogo'

export type AssistantMessageWrapperProps = PropsWithChildren<{}>

export const AssistantMessageWrapper = ({
  children,
}: AssistantMessageWrapperProps) => {
  return (
    <div className="chat-message cds--mt-05">
      <RaisedLogo />
      <div>
        <p className="cds--type-semibold text-secondary cds--mb-03">
          met<span className="text-warning">AI</span>nsights chat
        </p>
        {children}
      </div>
    </div>
  )
}
