import './App.css'

import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { Amplify } from 'aws-amplify'
import { ChatLayout } from './pages/chat/ChatLayout'
import { ChatList } from './components/chat-list/ChatList'
// import { Chat } from './pages/chat/Chat'
import { ConfirmEmail } from './pages/confirm-email/ConfirmEmail'
import { EmailConfirmed } from './pages/email-confirmed/EmailConfirmed'
import { Explore } from './pages/explore/Explore'
import { ForgottenPassword } from './pages/forgotten-password/ForgottenPassword'
import { Login } from './pages/login/Login'
import { NewPassword } from './pages/new-password/NewPassword'
import { Pages } from './constants/pages'
import { Params } from './constants/params'
import { PasswordReset } from './pages/password-reset/PasswordReset'
import { ProjectDetails } from './pages/project-details/ProjectDetails'
import { ProjectFinder } from './pages/project-finder/ProjectFinder'
import { ProjectSearch } from './pages/project-search/ProjectSearch'
import { ProtectedRoute } from './middleware/protected-route/ProtectedRoute'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Register } from './pages/register/Register'
import { Welcome } from './pages/welcome/Welcome'
import env from './environment'

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: env.USER_POOL_ID,
      userPoolClientId: env.USER_POOL_APP_CLIENT_ID,
    },
  },
})

const queryClient = new QueryClient()

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route path={Pages.CONFIRM_EMAIL} element={<ConfirmEmail />} />
          <Route path={Pages.EMAIL_CONFIRMED} element={<EmailConfirmed />} />
          <Route
            path={Pages.FORGOTTEN_PASSWORD}
            element={<ForgottenPassword />}
          />
          <Route path={Pages.LOGIN} element={<Login />} />
          <Route path={Pages.NEW_PASSWORD} element={<NewPassword />} />
          <Route path={Pages.PASSWORD_RESET} element={<PasswordReset />} />
          <Route path={Pages.REGISTER} element={<Register />} />
          <Route element={<ProtectedRoute />}>
            <Route
              path={Pages.CHAT}
              element={
                <ChatLayout>
                  <ChatList />
                </ChatLayout>
              }
            />

            <Route path={Pages.EXPLORE} element={<Explore />} />

            <Route path={Pages.PROJECT_FINDER} element={<ProjectFinder />} />
            <Route path={Pages.PROJECT_SEARCH} element={<ProjectSearch />} />
            <Route
              path={`${Pages.PROJECT_DETAILS}/:${Params.PROJECT_ID}`}
              element={<ProjectDetails />}
            />
            <Route path={Pages.WELCOME} element={<Welcome />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export default App
