import { useCallback, useEffect, useState } from 'react'

export function useSessionStorage<T>(key: string, initialValue?: T) {
  // Initialize state with the value from sessionStorage or the initial value
  const [storedValue, setStoredValue] = useState<T | undefined>(() => {
    const item = sessionStorage.getItem(key)
    try {
      if (item) {
        return JSON.parse(item) || item
      }
    } catch (e) {
      return item
    }
    return initialValue
  })

  // Update the state and sessionStorage whenever the value changes
  const setValue = useCallback(
    (value?: T) => {
      try {
        // Save to state
        setStoredValue(value)

        // Save to sessionStorage
        if (value) {
          sessionStorage.setItem(key, JSON.stringify(value))
        } else {
          sessionStorage.removeItem(key)
        }

        // Trigger a custom event to notify other parts of the app
        window.dispatchEvent(new Event(`storage-update`))
      } catch (error) {
        console.error('Error setting session storage', error)
      }
    },
    [key]
  )

  // Listen for storage events and update the state if the relevant key changes
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent | Event) => {
      // `StorageEvent` only triggers on other tabs/windows, so we also trigger `storage-update` manually
      if (event instanceof StorageEvent) {
        if (event.key === key && event.newValue !== null) {
          try {
            setStoredValue(JSON.parse(event.newValue))
          } catch (e) {
            setStoredValue(JSON.parse(JSON.stringify(event.newValue)))
          }
        }
      } else if (event.type === 'storage-update') {
        // Update from the custom event
        const item = sessionStorage.getItem(key)
        if (item) {
          try {
            const newItem = JSON.parse(item) || item
            setStoredValue(newItem)
          } catch (e) {
            setStoredValue(JSON.parse(JSON.stringify(item)))
          }
        }
      }
    }

    // Listen for both native and custom events
    window.addEventListener('storage', handleStorageChange)
    window.addEventListener('storage-update', handleStorageChange)

    // Cleanup
    return () => {
      window.removeEventListener('storage', handleStorageChange)
      window.removeEventListener('storage-update', handleStorageChange)
    }
  }, [key])

  return [storedValue, setValue] as const
}
