import './ChatMessage.scss'

import { AssistantMessage } from './AssistantMessage/AssistantMessage'
import { ChatMessageResponse } from 'models/responses'
import { UserMessage } from './UserMessage/UserMessage'
import { memo } from 'react'

export type ChatMessageProps = ChatMessageResponse & {
  onTyping?: Function
  lastMessage?: boolean
}

export const ChatMessage = memo(
  ({ message, role, onTyping, ...chatMessageResponse }: ChatMessageProps) => {
    if (role === 'user') {
      return <UserMessage message={message} />
    }

    if (role === 'assistant') {
      return (
        <AssistantMessage
          message={message}
          role={role}
          {...chatMessageResponse}
          onTyping={onTyping}
        />
      )
    }
    return null
  }
)
