import './PreviousChats.scss'

import { Link, useOutletContext } from 'react-router-dom'
import { memo, useEffect, useState } from 'react'

import { AuthContext } from 'middleware/protected-route/ProtectedRoute'
import { ChatResponse } from 'models/responses'
import { Loading } from 'carbon-components-react'
import { Pages } from 'constants/pages'
import { Params } from 'constants/params'
import { PreviousChatLink } from './PreviousChatLink'
import { getChatHistory } from 'services/apiService'
import { getDaysInThePast } from 'helpers/dateHelper'
import { useQuery } from '@tanstack/react-query'

type DatedChatHistory = {
  today: ChatResponse[]
  lastSevenDays: ChatResponse[]
  lastThirtyDays: ChatResponse[]
}

export const PreviousChats = memo(() => {
  const { accessToken = '' } = useOutletContext<AuthContext>()
  const queryParameters = new URLSearchParams(window.location.search)
  const chatParam = queryParameters.get(Params.CHAT) || ''
  const [datedChatHistory, setDatedChatHistory] = useState<DatedChatHistory>({
    today: [],
    lastSevenDays: [],
    lastThirtyDays: [],
  })

  const needsToRefresh =
    datedChatHistory.today.length > 0 &&
    datedChatHistory.today?.[0]?.history.filter(
      (item) => item.role === 'user'
    )[0]?.message === undefined

  const { data: chatHistory, isLoading } = useQuery({
    queryKey: ['chatHistory', chatParam],
    queryFn: () => getChatHistory({ accessToken }),
    enabled: !!accessToken,
    refetchInterval: needsToRefresh ? 100 : undefined,
  })

  useEffect(() => {
    if (chatHistory) {
      setDatedChatHistory(
        chatHistory.data
          .sort(
            (chatA: ChatResponse, chatB: ChatResponse) =>
              new Date(chatB.created_at).getTime() -
              new Date(chatA.created_at).getTime()
          )
          .reduce(
            (datedChatHistory: DatedChatHistory, chat: ChatResponse) => {
              try {
                const daysInPast = getDaysInThePast(new Date(), chat.created_at)
                if (daysInPast === 0) datedChatHistory.today.push(chat)
                else if (daysInPast <= 7)
                  datedChatHistory.lastSevenDays.push(chat)
                else if (daysInPast <= 30)
                  datedChatHistory.lastThirtyDays.push(chat)
              } catch (e: any) {}
              return datedChatHistory
            },
            { today: [], lastSevenDays: [], lastThirtyDays: [] }
          )
      )
    }
  }, [chatHistory])

  return (
    <div className="previous-chats-container">
      {isLoading && !datedChatHistory ? (
        <Loading small={true} withOverlay={false} />
      ) : (
        <>
          {datedChatHistory && datedChatHistory.today.length > 0 && (
            <div className="cds--mt-04">
              <small className="text-dark-gray">Today</small>
              {datedChatHistory.today.map(
                ({ id, project_id, history, created_at }: ChatResponse) => {
                  return (
                    <PreviousChatLink
                      key={id}
                      id={id}
                      chatParam={chatParam}
                      projectId={project_id}
                    >
                      <p>
                        <small className="text-dark-gray">
                          {new Date(created_at).toLocaleDateString('en-uk', {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                          })}
                        </small>
                        <br />
                        {!!project_id && (
                          <Link
                            to={`${Pages.PROJECT_DETAILS}/${project_id}`}
                            className="text-primary"
                          >
                            {project_id + ' - '}
                          </Link>
                        )}
                        {
                          history.filter((item) => item.role === 'user')?.[0]
                            ?.message
                        }
                      </p>
                    </PreviousChatLink>
                  )
                }
              )}
            </div>
          )}
          {datedChatHistory && datedChatHistory.lastSevenDays.length > 0 && (
            <div className="cds--mt-04">
              <small className="text-dark-gray">Previous 7 days</small>
              {datedChatHistory.lastSevenDays.map(
                ({ id, project_id, history }: ChatResponse) => {
                  return (
                    <PreviousChatLink
                      key={id}
                      id={id}
                      chatParam={chatParam}
                      projectId={project_id}
                    >
                      <p>
                        {!!project_id && (
                          <Link
                            to={`${Pages.PROJECT_DETAILS}/${project_id}`}
                            className="text-primary"
                          >
                            {project_id + ' - '}
                          </Link>
                        )}
                        {
                          history.filter((item) => item.role === 'user')?.[0]
                            ?.message
                        }
                      </p>
                    </PreviousChatLink>
                  )
                }
              )}
            </div>
          )}
          {datedChatHistory && datedChatHistory.lastThirtyDays.length > 0 && (
            <div className="cds--mt-04">
              <small className="text-dark-gray">Previous 30 days</small>
              {datedChatHistory.lastThirtyDays.map(
                ({ id, project_id, history }: ChatResponse) => {
                  return (
                    <PreviousChatLink
                      key={id}
                      id={id}
                      chatParam={chatParam}
                      projectId={project_id}
                    >
                      <p>
                        {!!project_id && (
                          <Link
                            to={`${Pages.PROJECT_DETAILS}/${project_id}`}
                            className="text-primary"
                          >
                            {project_id + ' - '}
                          </Link>
                        )}
                        {
                          history.filter((item) => item.role === 'user')?.[0]
                            ?.message
                        }
                      </p>
                    </PreviousChatLink>
                  )
                }
              )}
            </div>
          )}
        </>
      )}
    </div>
  )
})
