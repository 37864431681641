import React, { memo } from 'react'

import { MarkdownFormatter } from 'components/MarkdownFormatter/MarkdownFormatter'
import { RaisedLogo } from 'components/raised-logo/RaisedLogo'
import { User } from '@carbon/icons-react'

export type UserMessageProps = { message: string }

export const UserMessage = memo(({ message }: UserMessageProps) => {
  return (
    <div className="chat-message cds--mt-05">
      <RaisedLogo icon={<User size="24" />} />
      <div>
        <p className="cds--mb-03 cds--type-semibold">You</p>
        <MarkdownFormatter markdown={message} />
        {/* For delineating between messages in the copy text */}
        <div style={{ display: 'none' }}>----------</div>
      </div>
    </div>
  )
})
