import {
  ChatContentRequest,
  ChatHistoryRequest,
  ChatListRequest,
  ChatMetadataRequest,
  ChatVersionRequest,
  SearchProjectsRequest,
  SubmitChatRequest,
} from '../models/requests'
import {
  ChatMetadataResponse,
  ChatResponse,
} from '../models/responses'
import axios, { AxiosPromise } from 'axios'

import axiosRetry from 'axios-retry'
import env from '../environment'

axiosRetry(axios, {
  retries: 2,
  retryCondition(error) {
    switch (error.response?.status) {
      case 503:
        return true
      default:
        return false
    }
  },
})

const baseUrl = `${env.API_PROTOCOL}://${env.API_ENDPOINT}`

export const getChatHistory = ({
  accessToken,
  chat_id,
}: ChatHistoryRequest) => {
  return axios.post(
    `${baseUrl}/chat/history`,
    chat_id ? { chat_id } : {},
    getHeaders(accessToken)
  )
}

export const apiChatList = ({ accessToken }: ChatListRequest) => {
  // the return type for this is defined in PreviousChats.ts, how do we put it here?
  return axios.get(`${baseUrl}/chat`, getHeaders(accessToken))
}

export const searchProjects = ({
  accessToken,
  text,
  search_scope,
  metadata,
}: SearchProjectsRequest) => {
  return axios.post(
    `${baseUrl}/search`,
    { ...(text && { text }), ...(metadata && { metadata }), search_scope },
    getHeaders(accessToken)
  )
}

/*
export const submitChat = ({accessToken, message, chat_id = "new", metadata}: SubmitChatRequest) => {
    return axios.post(`${baseUrl}/chat/semantic_completion`, {message, chat_id, ...(metadata && { metadata })}, getHeaders(accessToken));
}
*/
export const apiSubmitNewChat = ({
  accessToken,
  message,
  metadata,
}: SubmitChatRequest): AxiosPromise<string> => {
  // determine the endpoint based on chat_id
  const endpoint = '/chat/new'

  // Make the post request to the chosen endpoint
  return axios.post(
    `${baseUrl}${endpoint}`,
    { message, ...(metadata && { metadata }) },
    getHeaders(accessToken)
  )
}

export const apiSubmitChat = ({
  accessToken,
  message,
  chat_id = 'new',
  metadata,
}: SubmitChatRequest) => {
  // determine the endpoint based on chat_id
  const endpoint = `/chat/${chat_id}/completion`

  // Make the post request to the chosen endpoint
  return axios.post(
    `${baseUrl}${endpoint}`,
    { message, chat_id, ...(metadata && { metadata }) },
    getHeaders(accessToken)
  )
}

export const apiChatVersion = ({
  accessToken,
  chat_id,
}: ChatVersionRequest): AxiosPromise<string> => {
  const endpoint = `/chat/${chat_id}/version`
  return axios.get(`${baseUrl}${endpoint}`, getHeaders(accessToken))
}

export const apiChatMetadata = ({
  accessToken,
  chat_id,
}: ChatMetadataRequest): AxiosPromise<ChatMetadataResponse> => {
  const endpoint = `/chat/${chat_id}/metadata`
  return axios.get(`${baseUrl}${endpoint}`, getHeaders(accessToken))
}

export const apiChatContent = ({
  accessToken,
  chat_id,
}: ChatContentRequest): AxiosPromise<ChatResponse> => {
  const endpoint = `/chat/${chat_id}/content`
  return axios.get(`${baseUrl}${endpoint}`, getHeaders(accessToken))
}

const getHeaders = (accessToken: string) => {
  return {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }
}
