import React from 'react'
import ReactMarkdown from 'react-markdown'

export type MarkdownFormatterProps = {
  markdown: string | null | undefined
  className?: string
}

/**
 * Adds tailwind classes to the markdown output
 */
export const MarkdownFormatter = ({
  markdown,
  className,
}: MarkdownFormatterProps) => {
  return (
    <ReactMarkdown
      components={{
        ul: ({ node, ...props }) => (
          <ul className="list-disc pl-5 space-y-2 py-2" {...props} />
        ),
        ol: ({ node, ...props }) => (
          <ol className="list-decimal pl-5 space-y-2 py-2" {...props} />
        ),
        li: ({ node, ...props }) => <li className="ml-3" {...props} />,
      }}
      className={className}
    >
      {markdown}
    </ReactMarkdown>
  )
}
