import './LoginLayout.scss'

import { Column, Grid } from 'carbon-components-react'

import logo from 'images/logo.png'
import logoTitle from 'images/logo-primary.svg'

export function LoginLayout({ children }: any) {
  return (
    <div style={{ overflowX: 'hidden' }}>
      <Grid>
        <Column
          lg={{ span: 4, offset: 6 }}
          md={{ span: 4, offset: 2 }}
          sm={{ span: 4, offset: 0 }}
          className="title-container"
        >
          <img src={logoTitle} alt="logo" className="logo" />
        </Column>
      </Grid>
      <Grid>
        <Column
          lg={{ span: 8, offset: 4 }}
          md={{ span: 6, offset: 1 }}
          sm={{ span: 4, offset: 0 }}
          className="login-container-wrapper"
        >
          <img src={logo} alt="logo" className="logo-right" />
          <img src={logo} alt="logo" className="logo-left" />
          <div className="login-container">
            <Grid>{children}</Grid>
          </div>
        </Column>
      </Grid>
    </div>
  )
}
