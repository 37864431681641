/**
 * A good guide to setting up Pino Logger
 * https://betterstack.com/community/guides/logging/how-to-install-setup-and-use-pino-to-log-node-js-applications/
 */

/**
 * middleware is bundled as a browser function causing issues with logger
 * https://github.com/vercel/next.js/discussions/33898
 * To get around this the browser.write function is added
 */

import pino from 'pino'

const logLevels: Record<number, string> = {
  10: 'TRACE',
  20: 'DEBUG',
  30: 'INFO',
  40: 'WARN',
  50: 'ERROR',
  60: 'FATAL',
}

export const logger = pino({
  level: localStorage.LOG_LEVEL || 'fatal',
  formatters: {
    level: (label) => {
      return { level: label.toUpperCase() }
    },
  },
  browser: {
    write: (logObject: Record<string, any>) => {
      try {
        const { level, time, msg, file, ...restOfLog } = logObject
        const log = {
          level: logLevels[level as number],
          file,
          msg,
          time,
          data: { ...restOfLog },
        }
        console.log(log)
      } catch (err) {
        if (err instanceof Error) {
          // Without a `replacer` argument, stringify on Error results in `{}`
          console.log(JSON.stringify(err, ['name', 'message', 'stack']))
        }
        console.log({ message: 'Error in logger: Unknown error type' })
      }
    },
  },
  timestamp: pino.stdTimeFunctions.isoTime,
})
