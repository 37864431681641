import { ChatSideBar } from 'components/ChatSideBar/ChatSideBar'
import { HeaderSideBarLayout } from 'layouts/HeaderSideBarLayout/HeaderSideBarLayout'
import { PropsWithChildren } from 'react'

export type ChatLayoutProps = PropsWithChildren<{}>

export const ChatLayout = ({ children }: ChatLayoutProps) => {
  return (
    <HeaderSideBarLayout sideBar={<ChatSideBar />}>
      {children}
    </HeaderSideBarLayout>
  )
}
