import { parseQueryString, writeQueryString } from 'helpers/queryString'
import { useCallback, useMemo } from 'react'

import { useSearchParams } from 'react-router-dom'

export const useUpdateSearchParams = <T extends object>() => {
  const [searchParams, setSearchParams] = useSearchParams()
  const searchParamsObject = useMemo(
    () => parseQueryString<T>(searchParams.toString()),
    [searchParams]
  )
  /**
   * updates and maintains the current search params
   */
  const updateSearchParams = useCallback(
    (newQuery: object): string => {
      return writeQueryString({ ...searchParamsObject, ...newQuery })
    },
    [searchParamsObject]
  )

  return {
    searchParams,
    searchParamsObject,
    updateSearchParams,
    setSearchParams,
  }
}
