import { Select, SelectItem } from 'carbon-components-react'

import { Params } from 'constants/params'
import { Project } from '../project/Project'
import { ProjectData } from 'models/responses'
import { useCallback } from 'react'
import { useSessionStorage } from 'hooks/useSessionStorage'

export type ProjectListProps = {
  isSavedSearch?: boolean
  projects: ProjectData[] | undefined
  updateProjects: (projects: ProjectData[]) => void
}

export type SortingValue = 'relevance' | 'date'

export function ProjectList({ projects, updateProjects }: ProjectListProps) {
  const [savedSorting, setSavedSorting] = useSessionStorage<SortingValue>(
    Params.CURRENT_SORTING,
    'relevance'
  )
  if (!savedSorting) {
    setSavedSorting('relevance')
  }
  const sortProjects = useCallback(
    (e: any) => {
      const sortingValue: SortingValue = e.target.value
      setSavedSorting(sortingValue)

      if (sortingValue === 'relevance') {
        updateProjects(
          [...(projects as ProjectData[])].sort(
            (projectA, projectB) =>
              (projectB.metadata.relevance || 0) -
              (projectA.metadata.relevance || 0)
          )
        )
      } else if (sortingValue === 'date') {
        updateProjects(
          [...(projects as ProjectData[])].sort(
            (projectA, projectB) =>
              (projectB.metadata.project_year || 0) -
              (projectA.metadata.project_year || 0)
          )
        )
      }
    },
    [projects, setSavedSorting, updateProjects]
  )

  return (
    <>
      <div className="display-flex justify-content-space-between">
        <span>
          There are {projects?.length} projects that match with your search
        </span>
        <div>
          <Select
            inline
            id="project-list-sort-by"
            labelText="Sort by"
            onChange={sortProjects}
            defaultValue={savedSorting}
          >
            <SelectItem value="relevance" text="Relevance" />
            <SelectItem value="date" text="Date" />
          </Select>
        </div>
      </div>
      <div>
        {projects?.map((project) => (
          <Project key={project.project_id} project={project} />
        ))}
      </div>
      {/*<Pagination
            className="cds--mt-07"
            backwardText="Previous page"
            forwardText="Next page"
            itemsPerPageText="Items per page:"
            onChange={function noRefCheck(){}}
            page={1}
            pageSize={10}
            pageSizes={[
            {
                text: '10',
                value: '10'
            },
            {
                text: '20',
                value: '20'
            },
            {
                text: '50',
                value: '50'
            }
            ]}
            size="md"
            totalItems={27}
        />*/}
    </>
  )
}
