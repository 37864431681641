import { HTMLAttributes } from 'react'
import { Link } from 'react-router-dom'
import { Pages } from 'constants/pages'
import logo from 'images/title.png'
import styles from './logo.module.css'

export type LogoProps = HTMLAttributes<HTMLImageElement>
export const Logo = (props: LogoProps) => {
  return (
    <Link to={Pages.WELCOME} className={styles.logoContainer}>
      <div style={{ height: '40px', display: 'flex', alignItems: 'flex-end' }}>
        <img
          src={logo}
          alt="metAI Insights"
          style={{ width: '150px' }}
          {...props}
        />
      </div>
    </Link>
  )
}
