import React, { PropsWithChildren, useMemo } from 'react'

import { NavLink } from 'react-router-dom'
import { Pages } from 'constants/pages'
import { Params } from 'constants/params'
import { useSessionStorage } from 'hooks/useSessionStorage'
import { writeQueryString } from 'helpers/queryString'

export type PreviousChatLinkProps = PropsWithChildren<{
  id: string
  projectId?: string | null
  chatParam?: string
}>

export const PreviousChatLink = ({
  children,
  chatParam,
  id,
  projectId,
}: PreviousChatLinkProps) => {
  const [, setChatId] = useSessionStorage(Params.CURRENT_CHAT, '')
  const qs = useMemo(
    () =>
      writeQueryString({
        [Params.CHAT]: id,
        [Params.PROJECT_ID]: projectId,
      }),
    [id, projectId]
  )
  return (
    <NavLink
      className={chatParam === id ? 'active-chat' : undefined}
      to={`${Pages.CHAT}${qs}`}
      onClick={() => {
        setChatId(id)
      }}
    >
      {children}
    </NavLink>
  )
}
