import './username.scss'

import { AuthContext } from 'middleware/protected-route/ProtectedRoute'
import { Stack } from '@carbon/react'
import { useOutletContext } from 'react-router-dom'

export const Username = () => {
  const { username } = useOutletContext<AuthContext>()

  return (
    <div className="user-name">
      <Stack orientation="horizontal" gap={5}>
        <button className="cds--type-semibold">
          {username.charAt(0).toUpperCase()}
        </button>
        <span className="cds--type-semibold">{username}</span>
      </Stack>
    </div>
  )
}
