import './ProjectSearch.scss'

import {
  FilterBar,
  FiltersState,
  initFilters,
} from 'components/filter-bar/FilterBar'
import { FinderResponse, ProjectData } from 'models/responses'
import { Form, Loading, TextInput } from 'carbon-components-react'
import { useCallback, useEffect, useState } from 'react'

import { AuthContext } from 'middleware/protected-route/ProtectedRoute'
import { Footer } from 'components/footer/Footer'
import { HeaderSideBarLayout } from 'layouts/HeaderSideBarLayout/HeaderSideBarLayout'
import { LoadingButton } from 'components/loading-button/LoadingButton'
import { Params } from 'constants/params'
import { ProjectList } from 'components/project-list/ProjectList'
import { logger } from 'utils/logger'
import { searchProjects } from 'services/apiService'
import { useOutletContext } from 'react-router-dom'
import { useSessionStorage } from 'hooks/useSessionStorage'
import { useUpdateSearchParams } from 'hooks/useUpdateSearchParams'

const log = logger.child({
  file: 'frontend/src/pages/project-search/ProjectSearch.tsx',
})

export function ProjectSearch() {
  const { updateSearchParams } = useUpdateSearchParams()
  const [isLoading, setIsLoading] = useState(true)
  const queryParameters = new URLSearchParams(window.location.search)
  const searchTerm = queryParameters.get(Params.SEARCH) || ''
  const [inputValue, setInputValue] = useState<string>(searchTerm)
  const [currentSearch, setCurrentSearch] = useSessionStorage<string>(
    Params.CURRENT_SEARCH,
    ''
  )
  const [projects, setProjects] = useSessionStorage<ProjectData[]>(
    Params.CURRENT_SEARCH_RESULTS
  )
  const [isSavedSearch, setIsSavedSearch] = useState(
    searchTerm === currentSearch
  )
  const { accessToken } = useOutletContext<AuthContext>()
  const [unfilteredProjects, setUnfilteredSearchResults] = useSessionStorage<
    ProjectData[]
  >(Params.UNFILTERED_SEARCH_RESULTS, [])
  const [, setFiltersState] = useSessionStorage<FiltersState>(
    Params.CURRENT_FILTERS
  )

  const searchForProjects = useCallback(
    (searchInput: string) =>
      searchProjects({
        accessToken,
        text: searchInput,
        search_scope: ['generated_content'],
      })
        .then(({ data }: { data: FinderResponse }) => {
          const listLength = data.hits.length
          setProjects(
            data.hits.map((project, index) => {
              project.metadata.relevance = listLength - index
              return project
            })
          )
          setCurrentSearch(searchInput)
          setUnfilteredSearchResults(data.hits)
          setFiltersState(initFilters(data.hits))
        })
        .catch((e) => log.error(e))
        .finally(() => {
          setIsLoading(false)
        }),
    [
      accessToken,
      setCurrentSearch,
      setFiltersState,
      setProjects,
      setUnfilteredSearchResults,
    ]
  )

  useEffect(() => {
    if (projects) {
      setIsLoading(false)
    } else if (accessToken) {
      searchForProjects(searchTerm)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateProjects = useCallback(
    (newProjects: ProjectData[]) => {
      setProjects(newProjects)
      setIsSavedSearch(true)
    },
    [setProjects]
  )

  const resetFilters = useCallback(() => {
    setProjects(unfilteredProjects)
  }, [setProjects, unfilteredProjects])

  const submitSearch = useCallback(
    async (e: any) => {
      e.preventDefault()
      const formData = new FormData(e.target)
      const { keyword } = Object.fromEntries(formData.entries())
      if (typeof keyword === 'string') {
        setIsLoading(true)
        setIsSavedSearch(false)
        resetFilters()
        updateSearchParams({ [Params.SEARCH]: keyword })
        await searchForProjects(keyword)
        setIsLoading(false)
      }
    },
    [resetFilters, searchForProjects, updateSearchParams]
  )

  return (
    <HeaderSideBarLayout
      sideBar={
        <FilterBar
          projects={projects}
          updateProjects={updateProjects}
          resetFilters={resetFilters}
          unfilteredProjects={unfilteredProjects}
          isSavedSearch={isSavedSearch}
        />
      }
    >
      <div className="project-container">
        <div className="cds--bg-gray-10-lighten">
          <div
            className="project-search-container"
            style={{ paddingTop: '4px', paddingBottom: '4px' }}
          >
            <Form onSubmit={submitSearch} className="search-form">
              <TextInput
                id="search"
                name="keyword"
                type="text"
                labelText="Your search"
                placeholder="Your search"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
              />

              <LoadingButton
                type="submit"
                className="btn-primary"
                value="Search"
                size="md"
                isLoading={false}
                isDisabled={inputValue === ''}
              />
            </Form>
          </div>
        </div>

        <div className="project-search-container">
          {isLoading ? (
            <Loading />
          ) : (
            <ProjectList projects={projects} updateProjects={updateProjects} />
          )}
        </div>
      </div>
      <Footer />
    </HeaderSideBarLayout>
  )
}
