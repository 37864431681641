import { Column, Form, TextInput } from 'carbon-components-react'

import { InlineBanner } from 'components/inline-banner/InlineBanner'
import { LoadingButton } from 'components/loading-button/LoadingButton'
import { LoginLayout } from 'layouts/login-layout/LoginLayout'
import { Pages } from 'constants/pages'
import { Params } from 'constants/params'
import { resetPassword } from 'aws-amplify/auth'
import { useNavigate } from 'react-router-dom'
import { useSessionStorage } from 'hooks/useSessionStorage'
import { useState } from 'react'

export function ForgottenPassword() {
  const [hasError, setHasError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const [, setEmailForReset] = useSessionStorage(Params.EMAIL_FOR_RESET)
  const handleResetPassword = async (e: any) => {
    e.preventDefault()
    setIsLoading(true)
    const form = e.target
    const formData = new FormData(form)
    const { email } = Object.fromEntries(formData.entries())
    try {
      await resetPassword({ username: email as string })
      setEmailForReset(email as string)
      navigate(Pages.NEW_PASSWORD)
    } catch (error: any) {
      setIsLoading(false)
      setHasError(true)
      setErrorMessage(error.message)
    }
  }

  return (
    <LoginLayout>
      <Column
        lg={{ span: 4, offset: 2 }}
        md={{ span: 4, offset: 1 }}
        sm={{ span: 4, offset: 0 }}
        className="cds--p-05"
      >
        <InlineBanner
          isVisible={hasError}
          setIsVisible={setHasError}
          subtitle={errorMessage}
        />
        <h1 className="cds--type-semibold">Forgot Password</h1>
        <p>
          Please write your email address, we'll send you an email with a code
          to reset your password
        </p>
        <Form aria-label="login form" onSubmit={handleResetPassword}>
          <TextInput
            labelText="Email address"
            placeholder="Email address"
            id="email"
            name="email"
          />
          <LoadingButton
            type="submit"
            className="btn-primary"
            value="Send code"
            isLoading={isLoading}
          />
        </Form>
      </Column>
    </LoginLayout>
  )
}
