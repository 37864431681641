import './RaisedLogo.scss'

import logo from 'images/logo.png'

export type RaisedLogoProps = {
  icon?: any
  size?: string
}

export function RaisedLogo({ size = '3rem', icon }: RaisedLogoProps) {
  return (
    <div className="raised-logo" style={{ width: size, height: size }}>
      {icon ? icon : <img src={logo} alt="logo" />}
    </div>
  )
}
