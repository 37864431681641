import {
  Button,
  ButtonKind,
  ButtonSize,
  Loading,
} from 'carbon-components-react'

import { HTMLAttributes } from 'react'

export type LoadingButtonProps = {
  isLoading: boolean
  isDisabled?: boolean
  value: string
  type?: 'button' | 'submit' | 'reset'
  kind?: ButtonKind
  className?: string
  size?: ButtonSize
} & HTMLAttributes<HTMLButtonElement>
export function LoadingButton({
  isLoading,
  isDisabled,
  value,
  type = 'button',
  kind = 'primary',
  className,
  size = 'lg',
  ...props
}: LoadingButtonProps) {
  return (
    <Button
      type={type}
      kind={kind}
      className={className}
      disabled={isLoading || isDisabled}
      size={size}
      {...props}
    >
      {value}{' '}
      {isLoading ? (
        <Loading withOverlay={false} small={true} className="cds--ml-04" />
      ) : (
        ''
      )}
    </Button>
  )
}
