import { AssistantMessageWrapper } from 'components/chat-message/AssistantMessage/AssistantMessageWrapper/AssistantMessageWrapper'
import { InlineLoading } from 'carbon-components-react'

// export type LoadingMessageProps = {}

export const LoadingMessage = () => {
  return (
    <AssistantMessageWrapper>
      <InlineLoading className="no-copy" />
    </AssistantMessageWrapper>
  )
}
