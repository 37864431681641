import {
  ChatMessageResponse,
  ProjectData,
  ProjectMetaData,
} from 'components/../models/responses'
import { FaCircleCheck, FaRegCopy } from 'react-icons/fa6'
import { Loading, Modal } from 'carbon-components-react'
import { Stack, Tooltip } from '@carbon/react'
import { memo, useEffect, useMemo, useRef, useState } from 'react'

import { AssistantMessageWrapper } from './AssistantMessageWrapper/AssistantMessageWrapper'
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary'
import { LinkButton } from 'components/LinkButton/LinkButton'
import { MarkdownFormatter } from 'components/MarkdownFormatter/MarkdownFormatter'
import { MetadataTags } from 'components/MetadataTags/MetadataTags'
import { Project } from 'components/project/Project'
import styles from './AssistantMessage.module.css'
import { useCopyWithFormatting } from 'hooks/useCopyWithFormatting'

export type AssistantMessageProps = ChatMessageResponse & {
  onTyping?: Function
  typingSpeed?: number
  lastMessage?: boolean
}

const sourceTags: Array<keyof ProjectMetaData> = [
  'client',
  'project_year',
  'project_lead',
  'country_markets',
  'program_type',
  'therapy_area',
  'methodologies',
  'respondent_type',
  'respondant_type',
]
export const AssistantMessage = memo(
  ({
    message = '',
    supplement,
    status = 'complete',
    typingSpeed = 10,
    onTyping,
    lastMessage,
  }: AssistantMessageProps) => {
    const [currentProject, setCurrentProject] = useState<
      ProjectData | undefined
    >()
    const messageRef = useRef<HTMLDivElement>(null)
    const [onCopy, copied] = useCopyWithFormatting<HTMLDivElement>(messageRef)

    const [openModal, setOpenModal] = useState(false)
    const [charIndex, setCharIndex] = useState(-1)
    const [initialStatus] = useState(status)
    const openProjectModal = (project: ProjectData) => {
      setCurrentProject(project)
      setOpenModal(true)
    }

    useEffect(() => {
      if (initialStatus !== 'complete' && charIndex < message.length) {
        let variableTypeSpeed = typingSpeed
        const buffer = message.length - charIndex
        if (buffer > 20) {
          variableTypeSpeed = typingSpeed / 2
        }

        const timeout = setTimeout(() => {
          setCharIndex(charIndex + 1)
          onTyping?.()
        }, variableTypeSpeed)

        return () => {
          clearTimeout(timeout)
        }
      }
    }, [charIndex, message, initialStatus, typingSpeed, onTyping])

    const slicedMessage =
      initialStatus === 'complete' || !lastMessage
        ? message
        : message.slice(0, charIndex)

    const { total, sourceCountWithData } = useMemo(() => {
      if (!supplement?.results) {
        return { total: 0, sourceCountWithData: 0 }
      }
      return supplement.results.project_metadata.reduce(
        (acc, val) => {
          /* 
          there is a spelling mistake on some results in
          the database this checks for the correctly spelt
          field first then tries the incorrect one
          */
          const value =
            val.metadata.total_number_respondents ||
            val.metadata.total_number_respondants

          if (isNaN(+value)) {
            return acc
          }
          acc.total += +value
          acc.sourceCountWithData += 1

          return acc
        },
        { total: 0, sourceCountWithData: 0 }
      )
    }, [supplement])

    return (
      <>
        <AssistantMessageWrapper>
          <div>
            <ErrorBoundary>
              <div ref={messageRef}>
                <MarkdownFormatter markdown={slicedMessage} />
              </div>
            </ErrorBoundary>
            {(initialStatus === 'complete' || charIndex === message.length) &&
              !!supplement?.results?.project_metadata &&
              supplement.results.project_metadata.length > 0 && (
                <>
                  <div
                    style={{
                      display: 'flex',
                      marginTop: '-16px',
                      marginBottom: '-8px',
                      justifyContent: 'flex-end',
                    }}
                    className="no-copy"
                  >
                    <Tooltip label="Copy" enterDelayMs={100} leaveDelayMs={0}>
                      <button className={styles.copyBtn} onClick={onCopy}>
                        {copied ? (
                          <FaCircleCheck size={16} style={{ color: 'green' }} />
                        ) : (
                          <FaRegCopy size={16} />
                        )}
                      </button>
                    </Tooltip>
                  </div>
                  <div style={{ display: 'none' }}>---</div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <p className="cds--type-semibold">
                      Source
                      {supplement.results?.project_metadata?.length > 1 &&
                        's'}{' '}
                      ({supplement.results?.project_metadata?.length})
                    </p>
                    <div style={{ fontSize: '14px' }}>
                      Total respondents: {total} ({sourceCountWithData}/
                      {supplement.results?.project_metadata?.length} source
                      {supplement.results?.project_metadata?.length > 1 && 's'})
                    </div>
                    <div style={{ display: 'none' }}>---</div>
                  </div>
                  <Stack gap={3}>
                    {supplement.results?.project_metadata.map((project) => {
                      const { metadata, project_id, asset_ids } = project
                      const {
                        total_number_respondents,
                        total_number_respondants,
                      } = metadata
                      return (
                        <div className={styles.project} key={project_id}>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'flex-start',
                              justifyContent: 'space-between',
                            }}
                          >
                            <div>
                              <div className={styles['project-lhs']}>
                                <div style={{ marginBottom: '4px' }}>
                                  <LinkButton
                                    onClick={() => openProjectModal(project)}
                                  >
                                    Project {project_id} - {metadata.title}
                                  </LinkButton>
                                </div>
                                <MetadataTags
                                  metadata={metadata}
                                  tagsToInclude={sourceTags}
                                />
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '2px',
                                  }}
                                >
                                  {asset_ids?.map((asset_id: string) => {
                                    return (
                                      <small
                                        key={asset_id}
                                        style={{ marginLeft: '20px' }}
                                      >
                                        - Asset ID: {asset_id}
                                      </small>
                                    )
                                  })}
                                </div>
                              </div>
                            </div>
                            <div className="no-copy">
                              <div style={{ fontSize: '14px' }}>
                                Respondents
                              </div>
                              <strong>
                                {total_number_respondents ||
                                  total_number_respondants ||
                                  '-'}
                              </strong>
                            </div>
                          </div>
                          <div style={{ display: 'none' }}>-----</div>
                        </div>
                      )
                    })}
                  </Stack>
                </>
              )}
          </div>
        </AssistantMessageWrapper>
        <Modal
          className="justify-content-right no-copy"
          passiveModal
          open={openModal}
          onRequestClose={() => setOpenModal(false)}
        >
          {currentProject ? (
            <Project project={currentProject as ProjectData} popupView={true} />
          ) : (
            <Loading small={true} withOverlay={false} />
          )}
        </Modal>
      </>
    )
  }
)
