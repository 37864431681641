import { Add } from '@carbon/icons-react'
import { Button } from 'carbon-components-react'
import { Pages } from 'constants/pages'
import { Params } from 'constants/params'
import { PreviousChats } from '../previous-chats/PreviousChats'
import { useNavigate } from 'react-router-dom'
import { useSessionStorage } from 'hooks/useSessionStorage'

export function ChatSideBar() {
  const navigate = useNavigate()
  const [, setChatValue] = useSessionStorage<string>(Params.CURRENT_CHAT)
  return (
    <>
      <Button
        kind="tertiary"
        renderIcon={Add}
        onClick={() => {
          setChatValue()
          navigate(Pages.CHAT)
        }}
      >
        New Chat
      </Button>

      <PreviousChats />
    </>
  )
}
