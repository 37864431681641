import './Welcome.scss'

import { Button, Column, Grid, Modal } from 'carbon-components-react'

import { Header } from 'components/header/Header'
import { Link } from 'react-router-dom'
import { Pages } from 'constants/pages'
import { RaisedLogo } from 'components/raised-logo/RaisedLogo'
import { Stack } from '@carbon/react'
import { Warning } from '@carbon/icons-react'
import { useState } from 'react'

export function Welcome() {
  const [openExample, setOpenExample] = useState(false)
  return (
    <>
      <Header />
      <Grid>
        <Column
          lg={{ span: 12, offset: 2 }}
          md={{ span: 6, offset: 1 }}
          sm={{ span: 4, offset: 0 }}
        >
          <Stack orientation="horizontal" gap={5}>
            <RaisedLogo />
            <h1 className="cds--type-semibold text-secondary cds--m-0">
              Welcome to met<span className="text-warning">AI</span>nsights
            </h1>
          </Stack>
          <p>
            metAInsights is a tool that leverages artificial intelligence (AI)
            to summarise and surface insights from Branding Science’s extensive
            healthcare insights knowledge base. Trained on over a thousand final
            projects, metAInsights provides human-like text responses to your
            queries.
          </p>
          <Stack
            orientation="horizontal"
            gap={3}
            className="text-primary cds--mt-03"
          >
            <Warning size="32" />
            <h2 className="cds--type-semibold cds--m-0">Please remember</h2>
          </Stack>
          <p>
            The development of metAInsights is an interactive process. While the
            tool aims for accuracy, users are advised to verify critical data.
            Your feedback is invaluable as we expand the tool’s capabilities.
          </p>
          <b>Key features:</b>
          <ul>
            <li>
              <b>Continuous learning:</b> The tool is continually learning from
              ongoing data and user interactions to improve its accuracy and
              functionality.
            </li>
            <li>
              <b>Intuitive understanding:</b> metAInsights aims to decipher the
              context of your queries, providing you with relevant responses
              from Branding Science data.
            </li>
            <li>
              <b>Robust knowledge base:</b> The tool includes projects dating
              back to May 2015, with new projects added periodically, so it may
              not display the most recent completions.
            </li>
          </ul>
          <Link to={Pages.CHAT} className="display-block w-0">
            <Button
              type="button"
              className="cds--mt-06 cds--mb-04 btn-primary display-block"
            >
              Open chat
            </Button>
          </Link>
          <Link
            to="#"
            className="text-primary cds--link-button"
            onClick={() => setOpenExample(true)}
          >
            How to write effective queries for metAInsights
          </Link>
          <Modal
            passiveModal
            className="modal-example"
            open={openExample}
            onRequestClose={() => setOpenExample(false)}
          >
            <p>
              For optimal interactions with the metAInsights tool, follow these
              guidelines to ensure your queries generate relevant responses:
            </p>
            <ol>
              <li>
                <b>Strive for clarity and precision.</b> The more specific you
                are, the more accurate and relevant the response will be.
              </li>
              <li>
                <b>Use relevant terminology and project-specific language.</b>{' '}
                For instance, if discussing drug development strategies, use
                specific terms like "clinical trial phase advancements" or
                "regulatory approval pathways".
              </li>
              <li>
                <b>Iterate and refine.</b> This iterative process can help
                narrow down the focus to obtain more detailed insights. If a
                question is too broad, consider splitting it into more focused,
                smaller questions.
              </li>
              <li>
                <b>
                  Request summaries or comparative analyses when appropriate
                </b>
                , such as "Compare market penetration strategies for biologics
                versus small molecule drugs". This approach can provide a broad
                overview or highlight differences in a clear, concise manner.
              </li>
              <li>
                <b>Highlight with quotation marks.</b> Enclose keywords or
                critical phrases in quotation marks to highlight them. This
                technique can help the model recognise the central elements of
                your query.
              </li>
              <li>
                <b>Avoid negative terms.</b> Refrain from using negative terms
                or double negatives (e.g., "not asthma"). These can confuse the
                model and lead to less accurate results.
              </li>
              <li>
                <b>Consider conversational limits:</b> Be aware that the model
                may not recall details from previous interactions after a
                certain number of exchanges, typically around six messages. This
                limitation means it might seem to "forget" earlier parts of the
                conversation, so you may need to restate or summarise key
                points.
              </li>
            </ol>
          </Modal>
          <div className="cds--mt-06 cds--pb-04">
            <p>Looking for a specific document or project?</p>
            <Link to={Pages.PROJECT_FINDER}>
              <Button
                type="button"
                kind="tertiary"
                className="cds--mt-05 btn-tertiary"
              >
                Project finder
              </Button>
            </Link>
          </div>
        </Column>
      </Grid>
    </>
  )
}
